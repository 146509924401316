<template>
  <fade-transition :duration="300">
    <div v-if="isVisible" :class="wrapperClass">
      <div class="interstitial-banner__backdrop" @click="closePopup" />

      <div class="interstitial-banner__content">
        <a-banner
          :banner-settings="bannerSettings"
          no-advertisement-label
          class="interstitial-banner__banner"
          @campaign-is-empty-status="onCampaignStatusChange"
        />
        <div class="interstitial-banner__close-icon" @click="closePopup">
          <i class="close-icon" />
        </div>
      </div>
    </div>
  </fade-transition>
</template>

<script>
import ABanner from 'shared/ABanner'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { INTERSTITIAL_BANNER_WRAPPER_CLASS } from '@/components/_layout/AInterstitialBanner/enums'
import * as types from '~/store/mutation-types'
import { mapActions, mapMutations } from 'vuex'
import mixins from '@/utils/mixins'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const BANNER_DISPLAY_DELAY = 300
const BANNER_DISPLAY_TIME = 5 * 1000

// stands for "banner visit count storage key"
const BANNER_VISIT_COUNT_STORAGE_KEY = 'bvcsk'
const DISPLAY_BANNER_VISIT_INDEXES = [0, 2, 4]

export default {
  name: 'AInterstitialBanner',
  mixins: [mixins.oneTrust, hydrationHelpers],
  components: { ABanner },
  props: {
    bannerSettings: propValidator([PROP_TYPES.OBJECT])
  },
  data() {
    return {
      isVisible: true,
      closeTimeoutId: null,
      openTimeoutId: null,
      isCampaignDisplayed: false,
      BANNER_SETTINGS,
      INTERSTITIAL_BANNER_WRAPPER_CLASS
    }
  },
  computed: {
    wrapperClass() {
      return {
        [INTERSTITIAL_BANNER_WRAPPER_CLASS]: true,
        hidden: !this.isCampaignDisplayed
      }
    },
    isConsentGiven() {
      return this.$_oneTrust_isConsentGivenForEntity(
        COOKIE_ENTITY_TYPE.DFP_BANNER
      )
    }
  },
  methods: {
    ...mapMutations({
      setInterstitialBannerAsVisible: types.SET_INTERSTITIAL_BANNER_AS_VISIBLE,
      setInterstitialBannerAsHidden: types.SET_INTERSTITIAL_BANNER_AS_HIDDEN,
      setInterstitialBannerPendingState:
        types.SET_INTERSTITIAL_BANNER_PENDING_STATE
    }),
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    shouldBannerBeDisplayed() {
      return DISPLAY_BANNER_VISIT_INDEXES.includes(
        this.getBannerVisitCountPerCurrentSession()
      )
    },
    getBannerVisitCountPerCurrentSession() {
      return Number(sessionStorage.getItem(BANNER_VISIT_COUNT_STORAGE_KEY)) || 0
    },
    incrementBannerVisitCountPerCurrentSession() {
      let visitCount = this.getBannerVisitCountPerCurrentSession()
      sessionStorage.setItem(BANNER_VISIT_COUNT_STORAGE_KEY, ++visitCount)
    },
    onCampaignStatusChange(isEmpty) {
      if (isEmpty) {
        this.setInterstitialBannerPendingState(false)
        this.isVisible = false
      } else {
        this.isCampaignDisplayed = true
        this.openPopup()
        this.setInterstitialBannerAsVisible()
      }
    },
    openPopup() {
      if (!this.isConsentGiven || !this.shouldBannerBeDisplayed()) {
        return
      }

      this.setInterstitialBannerPendingState(true)
      this.incrementBannerVisitCountPerCurrentSession()

      /**
       * Due to the fact that we call "this.$helper.showScroll()" in "AHeaderDesktop"
       * on "mounted", we resolve this concurrency by using "setTimeout"
       */
      this.openTimeoutId = setTimeout(() => {
        this.$_hydrationHelpers_hideScroll()

        this.closePopupWithDelay()
      }, BANNER_DISPLAY_DELAY)
    },
    closePopupWithDelay() {
      this.closeTimeoutId = setTimeout(this.closePopup, BANNER_DISPLAY_TIME)
    },
    closePopup() {
      clearTimeout(this.closeTimeoutId)
      this.$_hydrationHelpers_showScroll()
      this.isVisible = false
      this.setInterstitialBannerAsHidden()
      this.setInterstitialBannerPendingState(false)
    }
  },
  beforeMount() {
    this.setInterstitialBannerPendingState(true)
  },
  mounted() {
    // if (process.env.NODE_ENV === 'development') return

    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: () => {
          if (!this.shouldBannerBeDisplayed()) {
            this.incrementBannerVisitCountPerCurrentSession()
            this.setInterstitialBannerPendingState(false)
            this.isVisible = false
          }
        },
        entityType: COOKIE_ENTITY_TYPE.INTERSTITIAL_BANNER
      })
    )
  },
  beforeDestroy() {
    clearTimeout(this.openTimeoutId)
    this.setInterstitialBannerAsHidden()
    this.setInterstitialBannerPendingState(false)
  }
}
</script>

<style lang="scss" scoped>
.interstitial-banner__wrapper {
  z-index: $z-index-interstitial-banner;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;

  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }

  .interstitial-banner__content {
    width: 970px;
    height: 330px;
    position: relative;
    margin: auto;

    @include tablet {
      width: 300px;
      height: 250px;
    }

    .interstitial-banner__close-icon {
      width: 24px;
      height: 24px;
      background: $c--white;
      position: absolute;
      left: 0;
      top: -29px;
      cursor: pointer;

      .close-icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .interstitial-banner__banner {
      margin: 0;
    }
  }

  .interstitial-banner__backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
  }
}
</style>
